<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../../assets/img/ex_a.png" alt="" width="28">
        <h2>笼舍管理</h2>
      </div>
      <el-button size="small">历史记录</el-button>
    </div>
    <div class="contentBody">
      <div class="cageFrame flex">
        <strong class="flex tit"><img src="../../../assets/img/ex_b.png" alt="" width="20">笼架号：</strong>
        <el-tabs v-model="activeName" class="tabs">
          <el-tab-pane :label="'#'+item" :name="item" v-for="item in tabs" :key="item"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="cuddlesList flex">
        <div class="list flex" @click="toDetail(item.isUse,item.id)" v-for="item in cuddlesList[activeName]"
             :key="item.id" :class="{'unUse':item.isUse==0}">
          <div class="frameNo flex commonBg" v-if="item.isUse==1"></div>
          <div class="freeBg commonBg" v-else></div>
          <h4>{{ item.number }}</h4>
          <div class="headUser" v-if="item.isUse==1">
            <span class="username">{{ item.dirNickname }}</span>
            <img src="../../../assets/img/isClose.png" alt="" width="16" v-if="item.isClose==1">
          </div>
          <div class="free" v-else>空闲</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "cuddles",
  data() {
    return {
      activeName: '',
      tabs: [],
      cuddlesList: {},
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  methods: {
    getData() {
      this.$get('/subject/cage/byPage/' + this.user.companyId).then((res) => {
        if (res.status == 200) {
          for (let key in res.data) {
            this.tabs.push(key)
          }
          this.activeName = this.tabs[0]
          this.cuddlesList = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    toDetail(isUse, id) {
      if (isUse == 1) {
        this.$router.push({name: 'cuddlesDetail', query: {id: id}})
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/exper/cuddles";
</style>
